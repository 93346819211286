import React, { useState, useEffect, MutableRefObject, useRef } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import useGetEntry from '../Hooks/useGetEntry';
import useGetDatabaseMetadata from '../Hooks/useGetDatabaseMetadata';
import { EntryField } from '../Interfaces/EntryField';
import useAuth from '../Utility/useAuth';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowUpRightFromSquare, faMagnifyingGlass, faPen, faPlus, faPrint, faTrashCan } from '@fortawesome/free-solid-svg-icons';
import FormEntryField from './FormEntryField';
import { useFieldTypeStore } from '../Hooks/useFieldTypeStore';
import { IEntry, IEntryValue } from '../Utility/API';
import useUpdateEntry from '../Hooks/useUpdateEntry';
import APIError from '../Interfaces/APIError';
import { useAppStore } from '../Hooks/useAppStore';
import { NotificationTypes } from '../Interfaces/Notification';
import Util from '../Utility/Util';
import useGetMetadata from '../Hooks/useGetEntryMetadata';
import MetadataAttribute from '../Interfaces/MetadataAttribute';
import EntryMetadata from '../Interfaces/EntryMetadata';
import Image from '../Interfaces/Image';
import { useImageStore } from '../Hooks/useImageStore';
import useUploadImage from '../Hooks/useUploadImage';
import useDeleteImage from '../Hooks/useDeleteImage';
import ConfirmModal from './ConfirmModal';
import { useQueryClient } from '@tanstack/react-query';
import useSaveMetadata from '../Hooks/useSaveMetadata';
import useDeleteMetadata from '../Hooks/useDeleteMetadata';
import EditMetadata from './EditMetadata';
import EntryMetadataType from '../Interfaces/EntryMetadataType';
import PrintEntry from './PrintEntry';
import { useReactToPrint } from 'react-to-print';
import useDeleteEntry from '../Hooks/useDeleteEntry';
import useWindowSize from '../Hooks/useWindowSize';

interface IEntryProps {
  fromModal?: boolean;
  entryId?: number;
  onDelete?: () => void;
}

const Entry = (props: IEntryProps) => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const params = useParams<{ entryId: string }>();
  let entryId = Number(params.entryId);
  if(!entryId && props.entryId) {
    entryId = props.entryId;
  }

  const printRef : MutableRefObject<HTMLDivElement> = useRef<any>();
  const handlePrint = useReactToPrint({
    documentTitle: "Print This Document",
    // onBeforePrint: () => console.log('before printing'),
    // onAfterPrint: () => console.log('after printing'),
    removeAfterPrint: true
  });

  const defaultTab = { 0: 'Info '};
  const [ tabs, setTabs ] = useState<{[key: number]: string}>(defaultTab);
  const [ activeTab, setActiveTab ] = useState(0);
  const fieldTypes = useFieldTypeStore((state) => state.fieldTypes);
  const [ fieldNum, setFieldNum ] = useState(4);
  const { notify, setViewingMetadata } = useAppStore();
  const setImagePreview = useImageStore((state) => state.setImage);
  const [ editFields, setEditFields ] = useState(false);
  const [ upload, setUpload ] = useState<any>();
  const [ confirmDelete, setConfirmDelete ] = useState<number>();
  const [ confirmDeleteEntry, setConfirmDeleteEntry ] = useState<number>();
  const fileRef : MutableRefObject<HTMLInputElement> = useRef<any>();
  const [ changes, setChanges ] = useState<IEntryValue[]>([]);
  const [ editMetadata, setEditMetadata ] = useState(0); // -1 to edit new record

  const [ width ] = useWindowSize();
  const { data, isLoading } = useGetEntry(entryId);
  const { data: entryMetadata } = useGetDatabaseMetadata(data?.database?.id);
  const { data: metadata } = useGetMetadata(entryId);
  const { mutate: updateEntry } = useUpdateEntry(entryId, () => {
    Util.scrollToTop('smooth');
    notify({
      message: "Succesfully saved entry.",
      time: 4000
    });
    setChanges([]);
    setEditFields(false);
  }, (error: APIError) => {
    Util.scrollToTop('smooth');
    notify({
      message: error.message,
      altMessage: error.altMessage,
      time: 4000,
      type: NotificationTypes.Error
    });
  });
  const { mutate: uploadImage } = useUploadImage(entryId);
  const { mutate: deleteImage } = useDeleteImage(entryId);
  const { mutate: deleteEntry } = useDeleteEntry();
  const { auth } : any  = useAuth();

  useEffect(() => {
    if(width <= 300) {
      setFieldNum(1);
    } else if(width <= 500) {
      setFieldNum(2);
    } else {
      setFieldNum(4);
    }
  }, [width]);

  useEffect(() => {
    let tempTabs: { [key: number]: string} = defaultTab;
    if(entryMetadata) {
      entryMetadata.forEach((entryMetadataObj: EntryMetadataType) => {
          if(entryMetadataObj.type !== 'Relatives') {
            tempTabs[entryMetadataObj.id] = entryMetadataObj.type;
          }
      });
    }
    tempTabs[-1] = 'Images';
    setTabs({...tempTabs});
  }, [entryMetadata, data, metadata]);

  const handleEntryChange = (e: React.ChangeEvent<HTMLInputElement>, fieldId: number) => {
    let newValue = e.target.value;
    let valueObject = changes.find((i: { id: number }) => i.id === fieldId);
    if(valueObject) {
      valueObject.value = newValue;
    } else {
      changes.push({id: fieldId, value: newValue});
    }

    setChanges([...changes]);
  }

  const handleEntrySave = () => {
    if(changes.length) {
      let entry: IEntry = {
        database_id: data.database.id,
        entry_id: data.id,
        entry_type_id: data.type.id,
        values: changes
      };
      updateEntry(entry);
    }
  }

  const addMetadata = () => {
    if(metadata.find((i: { id: number }) => i.id === -1)) return;
    queryClient.setQueryData(['metadata', entryId], (oldData: EntryMetadata[]) => {
      oldData.push({
        id: -1,
        metadata_type_id: Number(activeTab),
        metadata: []
      });
      return oldData;
    });
    setEditMetadata(-1);
  }

  const stopEditing = () => {
    setEditMetadata(0);
    setViewingMetadata(false);
    if(metadata.find((i: { id: number }) => i.id === -1)) {
      queryClient.setQueryData(['metadata', entryId], (oldData: EntryMetadata[]) => {
        const oldIndex = oldData.findIndex((i: { id: number }) => i.id === -1);
        delete(oldData[oldIndex]);
        oldData.splice(oldIndex, 1);
        return [...oldData];
      });
    }
  }

  const getValueByFieldId = (fieldId: number, value: string) => {
    return changes.find((i: { id: number }) => i.id === fieldId)?.value ?? value;
  }

  if (isLoading) return <div className='text-center'>Loading...</div>;

  const renderEntryFields = () => {
    return data.database.entryFields.map((field: EntryField) => {
      const valueObject = data.values.find((i: { entry_field_id: number }) => i.entry_field_id === field.id);
      let value = valueObject?.value;
      if(fieldTypes[field.field_type_id] === 'Date' && value && editFields) {
        value = new Date(value).toISOString().split('T')[0];
      }
      return (
        <div key={field.id} className='flex gap-8 text-xs md:text-base'>
          <span className='text-right self-center basis-1/4'>{field.name}:</span>
          { !editFields &&
            <span className='text-left'>{value ?? 'N/A'}</span>
          }
          { editFields &&
            <FormEntryField
              fieldType={field.field_type_id}
              value={getValueByFieldId(field.id, value)}
              placeholder={field.name}
              name={`entry_field_${field.id}`}
              className='p-2 text-xs lg:text-base self-center outline-none ring-primary rounded focus:ring-2 shadow-lg focus:shadow-2xl max-w-[125px] sm:max-w-full lg:max-w-fit'
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                handleEntryChange(e, field.id);
              }}
              entryFieldId={field.id}
            />
          }
        </div>
      );
    });
  };

  const renderMetadata = (overrideType?: string) => {
    let metadataTypeId = Number(activeTab);
    let eMetadata = entryMetadata.find((i: { id: number; }) => i.id === Number(activeTab));
    if(overrideType) {
      eMetadata = entryMetadata.find((i: { type: string; }) => i.type === overrideType);
      if(!eMetadata) return;
      metadataTypeId = eMetadata.id;
    }

    return (
      <>
        { auth &&
          <button
            type='button'
            className='button-standard flex justify-between place-items-center gap-2 self-center text-xs sm:text-sm mb-4'
            onClick={addMetadata}
          >
            <FontAwesomeIcon icon={faPlus} />
            Add {entryMetadata.type}
          </button>
        }
        { eMetadata && eMetadata.attributes.length > 0 && metadata.filter((i: { metadata_type_id: number }) => i.metadata_type_id === metadataTypeId).length > 0 &&
          <>
            <span className='text-xs sm:text-sm self-center'>(Click to view related entries.)</span>
            <table className='w-full text-xs md:text-base mb-4'>
              <thead>
                <tr>
                  { eMetadata && eMetadata.attributes.map((attribute: MetadataAttribute, index: number) => (
                    <th key={attribute.id} className={`text-left ${index > fieldNum ? 'hidden lg:data-cell' : ''}`}>{attribute.attribute}</th>
                  ))}
                  <th className='text-center'>{ auth ? 'Edit' : 'View' }</th>
                </tr>
              </thead>
              <tbody>
                { metadata && metadata.filter((i : { metadata_type_id: number }) => i.metadata_type_id === metadataTypeId)?.map((metadataObj: EntryMetadata, index: number) => (
                  <tr key={index} className='odd:bg-gray-200 hover:bg-primary cursor-pointer' onClick={() => {
                    let tempState = {
                      entryMetadataId: metadataObj.id,
                      databaseId: data.database.id,
                      relatedSearch: Util.getEntryMetadataName(eMetadata.attributes, metadataObj.metadata),
                      overrideType: overrideType
                    };
                    localStorage.setItem('temp-state', JSON.stringify(tempState));
                    window.open('/related/search');
                  }}>
                    { eMetadata.attributes.map((attribute: MetadataAttribute, index: number) => (
                      <td key={index} className={`text-left ${index > fieldNum ? 'hidden lg:data-cell' : ''}`}>
                        {metadataObj.metadata?.find((i: { attribute_id: number; }) => i.attribute_id === attribute.id)?.value}
                      </td>
                    ))}
                    <td>
                      <div className='flex justify-center gap-4'>
                        { metadataObj.id !== editMetadata &&
                          <button
                            type='button'
                            title={ auth ? 'Edit' : 'View'}
                            className='flex justify-center w-5 h-5 sm:w-7 sm:h-7 place-items-center p-2 bg-primary rounded-full text-white border-black hover:border'
                            onClick={(e: React.MouseEvent) => {
                              e.stopPropagation();
                              stopEditing();
                              setViewingMetadata(true);
                              setEditMetadata(metadataObj.id);
                            }}
                          >
                            <FontAwesomeIcon className='text-xs' icon={auth ? faPen : faMagnifyingGlass} />
                          </button>
                        }
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </>
        }
        { !eMetadata || !metadata || !metadata.filter((i: { metadata_type_id: number }) => i.metadata_type_id === metadataTypeId).length &&
          <span className='text-center text-xs md:text-base'>
            No Data Found.
          </span>
        }
      </>
    )
  }

  const renderImages = () => {
    return (
      <>
        { auth &&
          <>
            { !upload &&
              <button
                type='button'
                className='button-submit'
                onClick={() => {
                  fileRef.current.click();
                }}
              >Upload</button>
            }
            <input
              ref={fileRef}
              type='file'
              accept='image/*'
              className='hidden'
              onChange={ async (e: React.ChangeEvent<HTMLInputElement>) => {
                if(!e.target.files) return;
                const file = e.target.files[0];
                setUpload(await Util.convertToBase64(file));
              }}
            />
            { upload &&
              <>
                <img src={upload} />
                <div className='flex gap-8 justify-between'>
                  <button
                    type='button'
                    className='button-standard'
                    onClick={() => {
                      setUpload(null);
                      fileRef.current.value = '';
                    }}
                  >Cancel</button>
                  <button
                    type='button'
                    className='button-submit'
                    onClick={() => {
                      setUpload(null);
                      uploadImage({
                        entryId: entryId,
                        base64: upload
                      });
                    }}
                  >Upload Image</button>
                </div>
              </>
            }
          </>
        }
        <div className='grid grid-cols-2 gap-4'>
          { data.images && data.images.map((image: Image) => (
            (!image.image.toLowerCase().endsWith(".pdf") ? (
              <span key={image.id} className='relative cursor-pointer outline-primary hover:outline' onClick={() => {
                setImagePreview(image.image);
              }}>
                <img src={image.image}/>
                { auth &&
                  <button
                    type='button'
                    className='absolute top-2 right-3 text-gray-500 hover:text-xl z-20'
                    title='Delete Image'
                    onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
                      e.stopPropagation();
                      setConfirmDelete(image.id);
                    }}
                  >
                    <FontAwesomeIcon icon={faTrashCan} />
                  </button>
                }
              </span>
            ) : (
              <span key={image.id} className='relative cursor-pointer outline-primary hover:outline'>
                <embed src={image.image}/>
                <span className='absolute inset-0 w-full h-full z-40 cursor-pointer' onClick={() => {
                  setImagePreview(image.image);
                }}></span>
                { auth &&
                  <button
                    type='button'
                    className='absolute top-2 right-3 text-gray-500 hover:text-xl z-20'
                    title='Delete Image'
                    onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
                      e.stopPropagation();
                      setConfirmDelete(image.id);
                    }}
                  >
                    <FontAwesomeIcon icon={faTrashCan} />
                  </button>
                }
              </span>
            )
          )))}
        </div>
        { !data.images.length &&
          <span className='text-xs md:text-base'>No Images Found.</span>
        }
      </>
    )
  }

  if (!data) {
    return <p>No entry data available</p>;
  }

  let tempMetadata;
  if(editMetadata && (tempMetadata = metadata.find((i: { id: number }) => i.id === editMetadata))) {
    const relativeMetadata = entryMetadata.find((i: { type: string }) => i.type === 'Relatives');
    if(activeTab === 0 && relativeMetadata) {
      tempMetadata.metadata_type_id = relativeMetadata.id;
      return <EditMetadata
        entryId={entryId}
        entryMetadata={tempMetadata}
        attributes={entryMetadata && entryMetadata?.find((i : { type: string }) => i.type === 'Relatives').attributes}
        onComplete={stopEditing}
        readOnly={!auth}
      />
    } else {
      return <EditMetadata
        entryId={entryId}
        entryMetadata={tempMetadata}
        attributes={entryMetadata && entryMetadata?.find((i : { id: number }) => i.id === Number(activeTab)).attributes}
        onComplete={stopEditing}
        readOnly={!auth}
      />
    }
  }

  return (
    <div className={'entry-parent-container flex flex-col w-full overflow-y-scroll max-h-[70vh]' + (!props.fromModal ? ' px-2 sm:px-8 lg:px-80 my-8' : ' px-2 md:px-4 lg:px-8 my-4')}>
      <div className='flex w-full gap-2 sm:gap-4 lg:gap-10 border-b border-b-gray-300 mb-4 text-xs md:text-base'>
        { tabs && Object.keys(tabs).length > 0 && Object.keys(tabs).map((typeId: any) => (
          <span
            key={typeId}
            onClick={() => {
              setActiveTab(typeId);
              setEditFields(false);
            }}
            className={'cursor-pointer ' + (activeTab == typeId ? 'text-primary underline underline-offset-[6px] decoration-2 font-bold underline-primary' : 'text-gray-500 font-semibold hover:text-gray-600 hover:font-bold hover:underline hover:underline-offset-[6px] hover:decoration-2')}
          >
            {tabs[typeId]}
          </span>
        ))}
        <span className='w-full hidden lg:block'>
          <span
            className='float-right flex gap-2 place-items-center justify-self-end cursor-pointer'
            onClick={() => {
              handlePrint(null, () => printRef.current);
            }}
          >
            <FontAwesomeIcon icon={faPrint} />
            Print Entry
          </span>
        </span>
      </div>
      {data && Number(activeTab) === 0 &&
        <div className='flex flex-col gap-2 justify-center place-items-center w-full relative text-xs md:text-base'>
          <span className='flex w-full justify-center font-bold'>Entry Details</span>
          {!editFields && auth &&
            <div className='absolute flex justify-center -top-1 right-4 sm:right-20'>
              <button
                type='button'
                className='flex place-items-center bg-primary p-2 rounded-lg text-white text-sm'
                onClick={() => {
                  setEditFields(true);
                }}
              >
                <FontAwesomeIcon icon={faPen} />
              </button>
            </div>
          }
          <div className={'flex w-full' + (editFields ? ' gap-4' : '')}>
            <div className={'hidden lg:flex' + (data.images.length > 0 ? ' basis-1/4' : '')}>
              { data.images.length > 0 &&
                (!data.images[0].image.toLowerCase().endsWith(".pdf") ? (
                  <div className='w-full max-w-[200px] h-auto min-w-[200px]'>
                    <img
                      src={data.images[0].image}
                      className='ring-primary rounded-lg hover:ring-4 cursor-pointer'
                      onClick={() => {
                        setImagePreview(data.images[0].image);
                      }}
                    />
                  </div>
                ) : (
                  <div className='w-full max-w-[200px] h-fit min-w-[200px] relative ring-primary rounded-lg hover:ring-4'>
                    <embed
                      src={data.images[0].image}
                      className='ring-primary rounded-lg hover:ring-4 cursor-pointer'
                      width={200}
                    />
                    <span className='absolute inset-0 w-full min-h-fit z-40 cursor-pointer' onClick={() => {
                      setImagePreview(data.images[0].image);
                    }}></span>
                  </div>
                )
              )}
            </div>
            <form autoComplete="off" className={'flex flex-col min-w-fit basis-3/4 gap-2' + (editFields ? ' gap-4' : '')}>
              { renderEntryFields() }
              { auth && editFields &&
                <div className='flex justify-between mt-4'>
                  <button
                    className='button-standard'
                    type='button'
                    onClick={() => {
                      setEditFields(false);
                      setChanges([]);
                    }}
                  >
                    Cancel
                  </button>
                  <button
                    className='button-standard bg-red-500 hover:bg-red-400'
                    type='button'
                    onClick={() => setConfirmDeleteEntry(entryId)}
                  >Delete</button>
                  <button
                    className='button-submit'
                    type='button'
                    onClick={handleEntrySave}
                  >
                    Save
                  </button>
                </div>
              }
            </form>
          </div>
          { entryMetadata && metadata && entryMetadata.length > 0 && entryMetadata?.filter((i: EntryMetadataType) => i.type === 'Relatives').length > 0 &&
            <>
              <h1 className="font-bold text-sm">Relatives</h1>
              {renderMetadata('Relatives')}
            </>
          }
        </div>
      }
      { Number(activeTab) !== 0 && Number(activeTab) !== -1 && metadata &&
        <div className='flex flex-col w-full justify-center'>
          { renderMetadata() }
        </div>
      }
      {
        Number(activeTab) === -1 && data.images &&
        <div className='flex flex-col w-full justify-center place-items-center gap-y-4'>
          { renderImages() }
        </div>
      }
      { !!confirmDelete &&
        <ConfirmModal
          title='Confirm Delete?'
          message="Are you sure you want to delete the image?"
          onApprove={() => {
            deleteImage({
              entryId,
              imageId: confirmDelete
            });
            setConfirmDelete(undefined);
          }}
          onDecline={() => {
            setConfirmDelete(undefined);
          }}
        />
      }
      { !!confirmDeleteEntry &&
        <ConfirmModal
          title='Confirm Delete?'
          message="Are you sure you want to delete this Entry?"
          onApprove={() => {
            deleteEntry(entryId, {
              onSuccess: () => {
                notify({
                  message: "Successfully Deleted Entry"
                });
                props.onDelete && props.onDelete();
              }
            });
            setConfirmDeleteEntry(undefined);
          }}
          onDecline={() => {
            setConfirmDeleteEntry(undefined);
          }}
        />
      }

      <PrintEntry
        ref={printRef}
      >
        <div className='flex flex-col gap-4'>
          <h1 className='font-bold text-center'>Entry Details</h1>
          <div>
            { renderEntryFields() }
          </div>
        </div>
      </PrintEntry>
    </div>
  );
};

export default Entry;