import React, { useState, useEffect, MutableRefObject, useRef } from 'react';
import { Route, Routes, Link, useNavigate } from 'react-router-dom';
import { QueryClient, useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowUpRightFromSquare, faAngleLeft, faAngleRight, faQuestion, faShare, faCopy } from '@fortawesome/free-solid-svg-icons';
import ReactPaginate from 'react-paginate';
import FormEntryField from './FormEntryField';
import Modal from './Modal';
import DropdownButton from './DropdownButton';
import useAuth from '../Utility/useAuth';
import API, { IEntry } from '../Utility/API';
import { IEntryValue } from '../Utility/API';
import Correction from './Correction';
import Util from '../Utility/Util';
import { EntryField } from '../Interfaces/EntryField';
import { Entry as EntryInterface } from '../Interfaces/Entry';
import { Database } from '../Interfaces/Database';
import Entry from './Entry';
import { EntryType } from '../Interfaces/EntryType';
import { useAppStore } from '../Hooks/useAppStore';

interface ISearchResultProps {
  recordCount: number;
  dataset: {
    databases?: Database[];
    database?: Database;
    entries: EntryInterface[];
    entryTypes: EntryType[];
  };
  fieldMapping: {
    [key: number]: string
  };
  recordTypeMap: {
    [key: number]: string
  };
  totalCount: number;
  paginateKey: string;
  onChange: Function;
  currentPage: number;
  refetchData?: Function;
  scrollToRef?: any;
}

function SearchResultSet(props: ISearchResultProps) {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { auth }: any = useAuth();
  const [page, setPage] = useState(1);
  const [ scrollTop, setScrollTop ] = useState(0);
  const [showModal, setShowModal] = useState(false);
  const [showCopyText, setShowCopyText] = useState(0);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [showCorrection, setShowCorrection] = useState(false);
  const [selectedId, setSelectedId] = useState<number>();
  const [entryFieldMap, setEntryFieldMap] = useState<{[key: number]: string}>();
  const [editFields, setEditFields] = useState(false);
  const [entryValues, setEntryValues] = useState<IEntryValue[]>([]);
  const { viewingMetadata } = useAppStore();
  let timeout: ReturnType<typeof setTimeout>;

  const { mutate: saveEntry } = useMutation({
    mutationFn: API.updateEntry,
    onSuccess: (data) => {
      queryClient.setQueryData(['entry', selectedId], data);
      if(props.refetchData) {
        props.refetchData();
      }
    }
  }); 

  let pageOptions = Util.getPageOptions();
  const defaultResults = 9;
  const [resultsPerPage, setResultsPerPage] = useState<string|number>(localStorage.getItem(`paginate-${props.paginateKey}`) ?? defaultResults);

  function groupValues(values: any) {
    // Group Values into 2s
    let groupedValues = [];
    let hasData = true;
    let i = 0;
    while(hasData) {
      let field1 = values[i];
      let field2 = values[i + 1];
      if(field1 || field2) {
        groupedValues.push([field1, field2]);
      } else {
        hasData = false;
      }
      i = i + 2;
    }

    return groupedValues;
  }

  const selectEntry = (recordId: number) => {
    setSelectedId(recordId);
    setShowModal(true);
  }

  const handleResultsPerPageChange = (key: string) => {
    setResultsPerPage(key);
    localStorage.setItem(`paginate-${props.paginateKey}`, key);
    // Do some query stuff
    props.onChange(page, key);
  }

  const handlePageChange = (selected: {[key:string]: number}) => {
    let page = selected.selected + 1;
    setPage(page);
    props.onChange(page, resultsPerPage);
  }

  const closeModal = () => {
    setShowConfirmModal(false);
    setShowCorrection(false);
    setShowModal(false);
  }

  // useEffect(() => {
  //   if(!editFields && !entryValues.length) {
  //     setShowCorrection(false);
  //     setShowModal(false);
  //   }
  // }, [editFields, entryValues])

  useEffect(() => {
    // Scroll to search results on mount
    if(props.dataset) {
      Util.scrollToTop(undefined, undefined, props.scrollToRef?.current?.offsetTop);
    }
    return () => {
      // Scroll to top on unmount
      // Util.scrollToTop();
    }
  }, [props.dataset])

  useEffect(() => {
    if(showCopyText) {
      timeout = setTimeout(() => {
        setShowCopyText(0);
      }, 3000);
    }
  }, [showCopyText]);

  useEffect(() => () => {
    clearTimeout(timeout);
  }, []);

  const handleEdit = () => {
    setEditFields(true);
  }

  const handleCorrection = () => {
    if(!selectedId) return;
    setShowConfirmModal(false);
    setShowCorrection(true);
  }

  const getFields = (databaseId: number) : EntryField[] => {
    let database = props.dataset.database;
    if(!database && props.dataset.databases) {
      database = props.dataset.databases.find((i : {id: number}) => i.id === databaseId);
    }
    return database?.entryFields ?? [];
  }

  const getValue = (fieldId: number, entry: EntryInterface) => {
    let valueObject = entry.values.find((i: {entry_field_id: number}) => i.entry_field_id === fieldId);
    let value = valueObject?.value ?? '';
    if(value?.field_type_name === 'Date') {
      value = new Date(value).toISOString().split('T')[0];
    }

    return value;
  }

  const onCorrectionSubmit = () => {
    setShowCorrection(false);
    setShowConfirmModal(false);
    setShowModal(false);
  }

  const getEntryTypeColor = (entryTypeId: number) => {
    const entryType = props.dataset.entryTypes.find((i: { id: number }) => i.id === entryTypeId);

    if(!entryType || !entryType.color) return;
    return {
      backgroundColor: entryType.color
    };
  }

  let lastPage = Math.ceil(props.totalCount / parseInt(resultsPerPage.toString()));
  let showingStart = parseInt(resultsPerPage.toString()) * (props.currentPage - 1);
  let showingEnd = showingStart + parseInt(resultsPerPage.toString());
  showingStart += 1;
  if(showingEnd > props.totalCount) {
    showingEnd = props.totalCount;
  }

  return (
    <>
      <div className={'grid gap-4 auto-rows-auto px-4 text-sm lg:text-lg max-w-fit mx-auto ' + (props.recordCount < 3 ? `grid-cols-1 md:grid-cols-2 xl:grid-cols-${props.recordCount}` : 'grid-cols-1 md:grid-cols-2 xl:grid-cols-3')}>
        {props.dataset && props.dataset.entries.map((record: any, key: number) => (
          <div key={record.id ?? key} className='border-0 rounded-xl bg-white relative w-full col-span-1'>
            { !!showCopyText && showCopyText === record.id &&
              <div className='flex absolute border bg-white rounded-lg p-4 z-20 right-28 -top-14'>URL Copied!</div>
            }
            <div className='flex flex-col sm:flex-row justify-between py-1 place-items-center bg-khaki border-0 rounded-t-xl w-full gap-y-2' style={getEntryTypeColor(record.entry_type_id)}>
              <span className='px-4 text-primary font-bold min-w-fit'>{props.dataset?.entryTypes?.find((i : { id: number }) => i.id === record.entry_type_id)?.name}</span>
              <div className='sm:flex grid grid-cols-2 w-full sm:justify-end gap-x-2 sm:gap-x-4 place-items-center'>
                <button type='button' className='flex gap-1 sm:gap-2 place-items-center' onClick={(() => {
                  const { host } = new URL(window.location.href);
                  Util.copyToClipboard(`${host}/entry/${record.id}`);
                  setShowCopyText(record.id);
                })}>
                  <span className='text-xs sm:text-sm'>Copy Link</span>
                  <FontAwesomeIcon title='Share Entry' className='cursor-pointer text-secondary w-[14px] h-[14px] sm:w-[18px] sm:h-[18px] mr-2' icon={faCopy}/>
                </button>
                <button title='View Entry' type='button' className='flex place-items-center' onClick={() => {
                    selectEntry(record.id);
                  }}
                >
                  <span className='text-xs sm:text-sm'>Open Entry</span>
                  <FontAwesomeIcon className='flex px-2 cursor-pointer text-secondary w-[14px] h-[14px] sm:w-[18px] sm:h-[18px]' icon={faArrowUpRightFromSquare} />
                </button>
              </div>
            </div>
            <div className='p-4'>
              <div className='grid grid-cols-2 gap-4 mb-4'>
                {getFields(record.database_id).map((entryField, key2) => (
                  <div key={key2} className='flex flex-col'>
                    <label className='text-primary font-bold'>{entryField.name}</label>
                    <span className='text-black'>{getValue(entryField.id, record)}</span>
                  </div>
                ))}
                {record.matches && record.matches.length > 0 &&
                  <div className='flex flex-col col-span-2'>
                    <label className='text-primary font-bold'>Search Matches</label>
                    <span className='text-black bg-yellow-300 w-fit'>{record.matches}</span>
                  </div>
                }
              </div>
            </div>
          </div>
        ))}
      </div>
      <div className={'flex rounded-lg w-full relative justify-center px-8 lg:px-0'}>
        <span className='flex absolute text-sm w-full justify-center'>{`Showing ${showingStart} - ${showingEnd} of ${props.totalCount} Results`}</span>
        <div className='flex lg:gap-x-8 relative place-items-center mt-8 text-sm lg:text-lg'>
          <ReactPaginate
            className='flex w-full gap-x-2 place-items-center z-20'
            pageRangeDisplayed={-1}
            marginPagesDisplayed={-1}
            onPageChange={handlePageChange}
            forcePage={props.currentPage - 1}
            nextClassName='flex w-full justify-end'
            nextLabel={
              <button className={props.currentPage >= lastPage ? 'hidden ' : '' + 'flex place-items-center px-2 lg:px-4 gap-x-2 py-2 lg:ml-2'}>
                Next
                <FontAwesomeIcon icon={faAngleRight} />
              </button>
            }
            previousLabel={
              <button className={props.currentPage === 1 ? 'hidden ' : '' + 'flex place-items-center px-2 lg:px-4 gap-x-2 py-2 mr-2'}>
                <FontAwesomeIcon icon={faAngleLeft} />
                Previous
              </button>
            }
            pageCount={Math.ceil(props.totalCount / parseInt(resultsPerPage.toString()))}
          />
          <div className='min-w-fit'>
            <DropdownButton
              dropdownItems={pageOptions}
              buttonText={pageOptions[resultsPerPage]}
              onChange={handleResultsPerPageChange}
            />
          </div>
        </div>
      </div>
      {showModal &&
        <Modal
          onClose={closeModal}
          title='Entry Details'
          size='max-w-5xl'
          content={
            <>
              {selectedId && !showCorrection &&
                <Entry entryId={selectedId} fromModal={true} onDelete={() => setShowModal(false)} />
              }
              {selectedId && showCorrection &&
                <Correction entryId={selectedId} onSubmit={onCorrectionSubmit} />
              }
            </>
          }
          footer={
            <div className='flex w-full justify-between gap-x-8 text-xs lg:text-lg'>
              {!auth && !showCorrection && !viewingMetadata &&
                <div
                  className='flex gap-2 place-items-center cursor-pointer'
                  onClick={() => {
                    setShowConfirmModal(true);
                  }}
                >
                  <FontAwesomeIcon icon={faQuestion} />
                  <span>Is something wrong with this entry?</span>
                </div>
              }
              <button type="button" className="button-standard" onClick={closeModal}>Close</button>
            </div>
          }
        />
      }
      {showConfirmModal &&
        <Modal
          onClose={() => {
            setShowConfirmModal(false);
          }}
          title='Make a Correction?'
          size='max-w-xl'
          content={
            <div className='flex py-4 justify-center px-16 text-xs lg:text-base'>
              Would you like to make a correction to the current entry?
            </div>
          }
          footer={
            <div className='flex justify-between gap-4 text-xs lg:text-base'>
              <button
                type="button"
                className="button-standard"
                onClick={() => {
                  setShowConfirmModal(false);
                }}
              >Cancel</button>
              <button
                type="button"
                className="button-submit w-auto"
                onClick={handleCorrection}
              >Yes</button>
            </div>
          }
        />
      }
    </>
  );
}

export default SearchResultSet;