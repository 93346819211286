import React, { Fragment, useState } from 'react';
import EntryMetadata from '../Interfaces/EntryMetadata';
import MetadataAttribute from '../Interfaces/MetadataAttribute';
import useGetEntryMetadata from '../Hooks/useGetEntryMetadata';
import useSaveMetadata from '../Hooks/useSaveMetadata';
import useDeleteMetadata from '../Hooks/useDeleteMetadata';
import Metadata from '../Interfaces/Metadata';
import ConfirmModal from './ConfirmModal';
import API from '../Utility/API';
import useFindMetadata from '../Hooks/useFindMetadata';
import Modal from './Modal';
import useAddEntryRelationship from '../Hooks/useAddEntryRelationship';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faChevronLeft } from '@fortawesome/free-solid-svg-icons';

interface IEditMetadataProps {
  entryId: number;
  entryMetadata: EntryMetadata;
  attributes: MetadataAttribute[];
  onComplete: Function;
  readOnly?: boolean;
}
const EditMetadata = (props: IEditMetadataProps) => {
  const { mutate: saveMetadata } = useSaveMetadata(props.entryId);
  const { mutate: deleteMetadata } = useDeleteMetadata(props.entryId);
  const { mutate: findMetadata } = useFindMetadata();
  const { mutate: addRelationship } = useAddEntryRelationship();
  const [ changes, setChanges ] = useState<Metadata[]>([]);
  const [ doDelete, setDoDelete ] = useState(false);
  const [ matches, setMatches ] = useState<EntryMetadata[]>([]);
  const [ selection, setSelection ] = useState<number|null>(null);

  const getValue = (attributeId: number) => {
    let change = changes.find((i: { attribute_id: number }) => i.attribute_id === attributeId);
    if(!change) return props.entryMetadata?.metadata?.find((i: { attribute_id: number }) => i.attribute_id === attributeId)?.value ?? '';

    return change.value ?? '';
  }

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>, attributeId: number) => {
    let value = e.target.value;
    const metadataId = props.entryMetadata?.metadata?.find((i: { attribute_id: number }) => i.attribute_id === attributeId)?.id;
    let change = changes.find((i: { attribute_id: number }) => i.attribute_id === attributeId);
    if(!change) {
      changes.push({
        id: metadataId ?? null,
        attribute_id: attributeId,
        value: value
      });
    } else {
      change.value = value;
    }

    setChanges([...changes]);
  }

  const handleSave = async () => {
    let entryMetadata = structuredClone(props.entryMetadata);
    const metadataIds = changes.map((change) => change.id);
    if(entryMetadata.metadata) {
      entryMetadata.metadata = entryMetadata.metadata.filter((obj: Metadata) => !metadataIds.includes(obj.id));
    } else {
      entryMetadata.metadata = [];
    }

    entryMetadata.metadata = [
      ...entryMetadata.metadata,
      ...changes
    ]

    findMetadata(entryMetadata, {
      onSuccess: (data: EntryMetadata[]) => {
        if(!data.length) {
          saveMetadata({
            entryId: props.entryId,
            entryMetadata
          }, {
            onSuccess: () => props.onComplete()
          });
        } else {
          setMatches(data);
        }
      }
    });
  }

  const closeSelections = () => {
    setSelection(null);
    setMatches([]);
  }

  const handleSelectionChange = (e: React.ChangeEvent<HTMLInputElement>, entryMetadataId: number) => {
    if(e.target.checked) {
      setSelection(entryMetadataId);
    } else {
      setSelection(null);
    }
  }

  const handleSelectionSave = () => {
    if(!selection) {
      const entryMetadata = structuredClone(props.entryMetadata);
      entryMetadata.metadata = changes;
      saveMetadata({
        entryId: props.entryId,
        entryMetadata
      }, {
        onSuccess: () => props.onComplete()
      });
    } else {
      addRelationship({
        entryId: props.entryId,
        entryMetadataId: selection,
        currentMetadataId: props.entryMetadata.id !== -1 ? props.entryMetadata.id : undefined
      }, {
        onSuccess: () => props.onComplete()
      });
    }
  }

  const renderMatches = () => {
    return (
      <div className='flex flex-col justify-center'>
        <table className='w-auto border-separate table-auto border-spacing-x-8'>
          <thead className='font-semibold'>
            <tr>
              <th className='text-center'>Select</th>
              { props.attributes.map((attribute: MetadataAttribute) => (
                <th key={attribute.id}>{ attribute.attribute }</th>
              ))}
            </tr>
          </thead>
          <tbody>
            { matches.map((entryMetadata: EntryMetadata) => (
              <tr key={entryMetadata.id}>
                <td className='text-center'>
                  <input
                    type='radio'
                    name='entry-selection'
                    className='w-4 h-4 cursor-pointer accent-primary'
                    checked={selection === entryMetadata.id}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                      handleSelectionChange(e, entryMetadata.id);
                    }}
                  />
                </td>
                { props.attributes.map((attribute: MetadataAttribute) => (
                  <td key={attribute.id}>{ entryMetadata.metadata?.find((i: { attribute_id: number }) => i.attribute_id === attribute.id)?.value ?? '' }</td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
        <div className='flex gap-2 mt-8 place-items-center'>
          <input
            id='default-selection'
            type='radio'
            className='accent-primary w-4 h-4 cursor-pointer'
            name='entry-selection'
            defaultChecked={true}
            onClick={() => {
              setSelection(null);
            }}
          />
          <label htmlFor='default-selection' className='cursor-pointer'>No Matches Found</label>
        </div>
      </div>
    )
  };

  return (
    <>
      {doDelete &&
        <ConfirmModal
          title='Delete Record?'
          message="Are you sure you want to delete this record?"
          onApprove={() => {
            deleteMetadata({
              entryId: props.entryId,
              entryMetadataId: props.entryMetadata.id
            }, {
              onSuccess: () => props.onComplete()
            });
          }}
          onDecline={() => {
            setDoDelete(false);
          }}
        />
      }
      <div className='flex flex-col px-2 md:px-4 xl:px-20 my-4'>
        <form>
          { props.readOnly &&
            <h1 className='text-center font-bold mb-4'>View Record</h1>
          }
          { !props.readOnly &&
            <h1 className='text-center font-bold mb-4'>{ props.entryMetadata.id > 0 ? 'Edit Record' : 'Create Record'}</h1>
          }
          <div className='grid grid-cols-2 gap-y-2 md:gap-2 place-items-center'>
            { props.attributes.map((attribute: MetadataAttribute) => (
              <Fragment key={attribute.id}>
                <label htmlFor={attribute.id.toString()} className='justify-self-end w-28 sm:w-32 md:w-40 lg:w-auto font-bold text-xs md:text-base'>{attribute.attribute}{attribute.required ? '*' : ''}:</label>
                { props.readOnly &&
                  <span className='text-xs lg:text-base'>{getValue(attribute.id)}</span>
                }
                { !props.readOnly &&
                  <input
                    type='text'
                    id={attribute.id.toString()}
                    className='p-2 border border-gray-200 rounded-lg outline-none ring-primary focus:ring-2 w-28 sm:w-32 md:w-40 lg:w-auto text-xs md:text-base'
                    placeholder={attribute.attribute}
                    value={getValue(attribute.id)}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                      handleInputChange(e, attribute.id);
                    }}
                  />
                }
              </Fragment>
            ))}
          </div>
            { !props.readOnly &&
              <span className='flex my-2 text-sm justify-center'>* = Required</span>
            }
            <div className='flex justify-between gap-2 my-4 px-2 md:px-4 xl:px-20 text-xs lg:text-base'>
              <button
                type='button'
                className='button-standard gap-2 place-items-center'
                onClick={() => props.onComplete()}
              >
                <FontAwesomeIcon icon={faChevronLeft} className='text-xs'/>
                Back
              </button>
              { !props.readOnly &&
                <>
                  { props.entryMetadata.id > 0 &&
                    <button
                      type='button'
                      className='button-standard bg-red-600 hover:bg-red-500 text-white'
                      onClick={() => {
                        setDoDelete(true);
                      }}
                    >Delete</button>
                  }
                  <button
                    type='button'
                    className='button-submit w-auto'
                    onClick={handleSave}
                  >Save</button>
                </>
              }
            </div>
        </form>
      </div>
      { matches && matches.length > 0 &&
        <Modal
          size='max-w-5xl'
          title='Found Matches'
          onClose={closeSelections}
          content={
            <div className='flex flex-col justify-center text-base py-2 px-8'>
              <h1 className='text-center font-bold'>Is this entry related to any of the matches below?</h1>
              { renderMatches() }
            </div>
          }
          footer={
            <div className='flex justify-between w-full px-8'>
              <button className='button-standard'
                type='button'
                onClick={closeSelections}
              >Cancel</button>
              <button
                className='button-submit'
                type='button'
                onClick={handleSelectionSave}
              >Save</button>
            </div>
          }
        />
      }
    </>
  );
}

export default EditMetadata;